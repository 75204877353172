import React, { useEffect, useState, useRef } from "react";
import constant from "../../_config/constant";
import airlineEmails from "../../_config/airlineEmail.json";
import { userService } from "../../_services";
import { toast } from "react-toastify";
import { logout, getData, saveData } from "../../_helpers";
import PhoneInput from "react-phone-input-2";
import { AiOutlineMail, AiOutlinePlus } from "react-icons/ai";
import "react-phone-input-2/lib/style.css";
import { FcGoogle } from "react-icons/fc";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { BsFacebook } from "react-icons/bs";
import GoogleLogin from "react-google-login";
import CustomModal from "./../ReactModal";
import OtpInput from "react-otp-input";
import { PhoneVerificationModal } from "./PhoneVerificationModal";
import { EmailVerificationModal } from "./EmailVerificationModal";
import { MdPermContactCalendar } from "react-icons/md";
import { PersonaVerifyModal } from "./PersonaVerificationModal";
import { AppConfig } from "../../Config";
import { processMediaUrl } from "../../_helpers/utils";

let acceptedCountriesPhone = ["us"];
if (AppConfig.APP_ENV !== "production") {
  airlineEmails.data.push({
    Domain: "@maildrop.cc",
    "Airline name": "Test Airline",
  });
  acceptedCountriesPhone.push("in");
}

function TextField({
  value,
  setValue,
  label,
  children,
  verified,
  type = "text",
  error = false,
  ...props
}) {
  const [editable, setEditable] = useState(false);

  return (
    <div className="tw-flex tw-flex-col">
      <label className="tw-text-sm">
        {label}
        {verified ? (
          ""
        ) : (
          <span className="tw-text-red-500 tw-ml-2">Unverified</span>
        )}
      </label>
      <div
        className={`tw-flex tw-justify-start tw-items-center tw-rounded tw-shadow-md tw-w-full tw-p-3 ${error ? "tw-border-red-500 border " : ""
          }`}
      >
        <input
          // disabled={!editable}
          type={type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={
            " tw-rounded tw-bg-transparent tw-border-0 tw-full tw-flex-grow"
          }
          {...props}
        />
      </div>
    </div>
  );
}

function UpdateProfile() {
  const phoneRef = useState();
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [personalEmailError, setPersonalEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [mobileCode, setmobileCode] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [airlineEmail, setAirlineEmail] = useState("");
  const [airlineEmailError, setAirlineEmailError] = useState("");
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const emailOtpRef = useRef();
  const [phoneModal, setPhoneModal] = useState(false);
  const [personaModal, setPersonaModal] = useState(false);
  const [personalEmailBackup, setpersonalEmailBackup] = useState("");

  const togglePersonaModal = () => {
    setPersonaModal(!personaModal);
  };
  const toggleEmailModal = () => {
    setEmailModal(!emailModal);
  };
  const otpRef = useRef();
  const [dob, setDob] = useState("");
  const [status, setStatus] = useState(false);
  const [fileData, setFileData] = useState("");
  const [profile, setProfile] = useState("/images/profile/profile.png");
  const [refreshKey, setRefreshKey] = useState(0);
  const [user, setUser] = useState(null);
  const [emailVerification, setEmailVerificationStatus] = useState(false);
  const [
    personalEmailVerificationStatusBackup,
    setPersonalEmailVerificationStatusBackup,
  ] = useState(1);
  const [backupAirlineEmail, setBackupAirlineEmail] = useState("");
  const [backupAirlineEmailStatus, setBackupAirlineEmailStatus] = useState(0);

  const resendEmailOtp = async (emailOrPhone) => {
    try {
      const res = await userService.updatePersonalEmailAndOtp({
        emailId: emailOrPhone,
      });
      if (res.status) {
        toast.success("OTP sent successfully!", { zIndex: 9999 });
        toggleEmailModal();
      } else toast.error(res.message);
    } catch {
      toast.error("Something went wrong!", { zIndex: 9999 });
    }
  };

  const verifyEmailOtp = async (otp) => {
    try {
      setEmailVerificationStatus(true);
      const res = await userService.verifyPersonalEmailOTP({ otp });

      setEmailVerificationStatus(false);
      if (res.status) {
        setRefreshKey((oldKey) => oldKey + 1);
        toggleEmailModal();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setEmailVerificationStatus(false);
    }
  };
  const resendMobileOtp = () => {
    userService
      .sendVerifyPhoneOtp({
        phone: phone,
        countryCode,
      })
      .then((objS) => {
        if (objS.status) {
          toast.success(objS.message, { zIndex: 9999 });
          togglePhoneModal();
        } else {
          toast.error(objS.message, { zIndex: 9999 });
        }
      });
  };

  const togglePhoneModal = () => {
    setPhoneModal(!phoneModal);
  };

  const verifyOtp = (otp) => {
    if (otp.length < 3) return;
    setPhoneVerification(true);
    userService.verifyPhone({ otp }).then((objS) => {
      if (objS.status) {
        toast.success(objS.message);
        togglePhoneModal();
        // window.$("#addRoomModal").modal("hide");
        // window.$(".modal-backdrop").remove();
        setPhoneVerification(false);
        setRefreshKey((oldKey) => oldKey + 1);
      } else {
        toast.error(objS.message);
        setPhoneVerification(false);
        return;
      }
    });
  };
  const getFile = (e) => {
    if (e.target.files[0]) {
      setFileData(e.target.files[0]);
      let src = new FileReader();
      const url = URL.createObjectURL(e.target.files[0]);
      setProfile(url);
    }
  };
  const [personalEmailVerification, setPersonalEmailVerification] = useState(
    false
  );
  const [phoneVerification, setPhoneVerification] = useState(false);
  const [airlineEmailVerification, setAirlineEmailVerification] = useState(
    false
  );

  const [phoneAndCountryCodeBackup, setPhoneAndCountryCodeBackup] = useState(
    ""
  );
  const [
    phoneAndCountryCodeVerificationStatusBackup,
    setPhoneAndCountryCodeVerificationStatusBackup,
  ] = useState(1);

  const sendAirlineEmailVerificationLink = () => {
    if (!user.airlineEmail) {
      toast.info("Please update airline email in profile section.");
      return;
    }
    setFlag2(true);
    userService
      .sendAirlineEmailVerificationLink({
        emailType: "airline",
        emailId: airlineEmail,
      })
      .then(
        (objS) => {
          if (objS.status) {
            toast.success(objS.message);
          } else {
            toast.error(objS.message);
          }
          setFlag2(false);
        },
        (error) => {
          setFlag2(false);
        }
      );
  };

  const updateIdVerificationStatus = () => {
    userService.updateIdVerificationStatus().then((objS) => {
      if (objS.status) {
        toast.success(objS.message);
        setRefreshKey((oldKey) => oldKey + 1);
      } else {
        toast.error(objS.message);
      }
    });
  };

  const handleAirlineEmail = (email) => {
    console.log(email);
    setAirlineEmail(email);
    setAirlineEmailError(false);
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setAirlineEmailVerification(1);
        setAirlineEmailError(true);
        console.log("Airline Email not valid", email);
        return;
      }
      // domain includes listed airline check
      const domain = email.split("@")[1];
      const isValid = airlineEmails.data.filter((el) => {
        console.log(el.Domain === domain);
        return el.Domain === `@${domain}`;
      });

      if (isValid.length === 0) {
        console.log(isValid, domain, "Airline Domain is not valid");
        setAirlineEmailError(true);
        setAirlineEmailVerification(1);
        return;
      }

      if (email !== backupAirlineEmail) {
        console.log(
          "given email not equal, so verify button should show",
          email
        );
        setAirlineEmailVerification(0);
      } else {
        setAirlineEmailVerification(backupAirlineEmailStatus);
        console.log("Both Email are same", email);
      }
    }
  };

  const handlePersonalEmail = (email = "") => {
    setPersonalEmail(email);
    setPersonalEmailError(false);
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      console.log("personalEmail is not valid", email);
      setPersonalEmailError(true);
      setPersonalEmailVerification(1);
      return;
    }

    const domain = email.split("@")[1];
    const isValid = airlineEmails.data.filter(
      (el) => el.Domain === `@${domain}`
    );
    if (isValid.length > 0) {
      console.log("email contain airline email", email);
      setPersonalEmailError(true);
      setPersonalEmailVerification(1);
      return;
    }
    if (personalEmailBackup !== email) {
      console.log("personalEmail is not equal to current url", email);
      setPersonalEmailVerification(0);
    } else {
      console.log("personalEmail is equal", email);
      setPersonalEmailVerification(personalEmailVerificationStatusBackup);
    }
  };

  const handlePhoneNumber = (value, e) => {
    if (phoneAndCountryCodeBackup !== value) {
      setPhoneVerification(0);
    } else {
      setPhoneVerification(phoneAndCountryCodeVerificationStatusBackup);
    }
    const phoneInput = value.slice(e.dialCode.length);
    setPhone(phoneInput);
    setCountryCode(e.dialCode);
  };

  useEffect(() => {
    userService.getDetail({}).then(
      (objS) => {
        if (objS.status) {
          setUser(objS.data);
          setFirstName(objS.data.name.split(" ")[0]);
          setLastName(objS.data.name.split(" ")[1]);
          setPersonalEmail(objS.data.personalEmail);
          setpersonalEmailBackup(objS.data.personalEmail);
          setPhone(objS.data.phone);
          setCountryCode(objS.data.countryCode);
          setAirlineEmail(
            objS.data.airlineEmail === "null" ? "" : objS.data.airlineEmail
          );
          setDob(objS.data.dob);
          setPersonalEmailVerification(objS.data.personalEmailVerification);
          setPersonalEmailVerificationStatusBackup(
            objS.data.personalEmailVerification
          );
          setPhoneVerification(objS.data.phoneVerification);
          setAirlineEmailVerification(objS.data.airlineEmailVerification);
          setBackupAirlineEmailStatus(objS.data.airlineEmailVerification);
          setBackupAirlineEmail(objS.data.airlineEmail);
          setPhoneAndCountryCodeBackup(
            `${objS.data.countryCode ? objS.data.countryCode : ""}${objS.data.phone ? objS.data.phone : ""
            }`
          );
          setPhoneAndCountryCodeVerificationStatusBackup(
            objS.data.phoneVerification
          );

          if (objS.data.avatar) {
            setProfile(processMediaUrl(objS.data.avatar));
            var host = getData();
            if (host && host.avatar) {
              host.avatar = objS.data.avatar;
              saveData(host);
            }
          }
          setTimeout(() => {
            if (phoneRef.current) {
              phoneRef.current.tel.value = objS.data.phone;
            }
          }, 1000);
        }
      },
      (error) => { }
    );
  }, [refreshKey]);

  const isAdult = (date) => {
    let Bday = new Date(date); //formatting input as date
    let ageY = (Date.now() - Bday) / 31557600000;
    if (ageY < 18) return false;
    else return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // if (airlineEmailError) {
    //   toast.error("Invalid Airline Email");
    //   return;
    // }

    // if (personalEmailError) {
    //   toast.error("Invalid Personal Email");
    //   return;
    // }

    const phoneTest = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(
      `${countryCode}${phone}`
    );

    // if (phone.length <= 4 || phone.length >= 15) {
    //   toast.error("Invalid Phone");
    //   return;
    // }

    if (!isAdult(dob)) {
      toast.error("You must be 18 years old or over to create an account.");
      return;
    }

    // setStatus(true);

    userService
      .UpdateProfile({
        name: firstName + " " + lastName,
        personalEmail,
        phone: phone,
        countryCode: countryCode,
        airlineEmail: airlineEmail ? airlineEmail : "",
        dob,
        fileData,
      })
      .then(
        (objS) => {
          if (objS.status) {
            toast.success(objS.message);
            setRefreshKey((oldKey) => oldKey + 1);
          } else {
            toast.error(objS.message);
          }
          setStatus(false);
        },
        (error) => {
          setStatus(false);
          toast.error(error.message);
        }
      );
  };

  async function handleSocialAccountConnection(data) {
    if (user.email === data.email) {
      const res = await userService.updateSocialAuthenticationStatus({
        socialType: data.authType,
        socialId: data.id,
      });

      if (res.status) {
        toast.success(res.message);
        const obj = { ...user };
        obj[data.dataKey] = 1;
        localStorage.setItem(constant.DATA_KEY, JSON.stringify(obj));
        setUser({ ...user, [data.dataKey]: 1 });
      } else toast.error(res.message);
    } else {
      toast.error(
        "Social ID cannot be connected with any other email or mobile!"
      );
      return;
    }
  }

  return (
    <div>
      {user && (
        <div className="tw-mt-4">
          <div className="tw-relative tw-w-32 tw-h-32 tw-mx-auto">
            <img
              className="tw-w-full tw-h-full tw-rounded-full tw-object-cover tw-ring-2 tw-ring-[#23426e]"
              src={profile}
              alt="Profile"
              onError={(e) => {
                e.target.onerror = null; // prevents looping
                e.target.src = "/images/user.png"; // fallback placeholder image
              }}
            />
            <label
              htmlFor="profile_user"
              className="tw-absolute pen-btn tw-bg-white tw-rounded-full tw-shadow-md tw-cursor-pointer"
            >
              <img
                className="tw-h-1 tw-w-1 absolute bottom-1 right-1 cursor-pointer  rounded-full object-cover"
                src="/images/material-pen.png"
                alt="dev"
              />
            </label>
          </div>
          <input
            id="profile_user"
            type="file"
            name="images"
            onChange={getFile}
            accept="image/*"
            required
            className="tw-hidden"
          />
          <form onSubmit={onSubmit}>
            <div className="tw-grid-cols-1 lg:tw-grid-cols-2 tw-grid tw-gap-x-10 tw-grid tw-gap-y-8 tw-grid tw-py-10">
              <TextField
                verified={true}
                value={firstName}
                placeholder="First Name"
                setValue={setFirstName}
                label="First Name"
                required
              />
              <TextField
                verified={true}
                value={lastName}
                placeholder="Last Name"
                setValue={setLastName}
                label="Last Name"
                required
              />

              <div className="tw-flex tw-flex-col">
                <label className="tw-text-sm tw-flex tw-flex-row">
                  Email{" "}
                  <p className="tw-text-red-500 tw-ml-2">
                    {personalEmailError && "Invalid"}
                  </p>
                </label>
                <div
                  className={`tw-flex tw-justify-start tw-items-center tw-rounded tw-shadow-md tw-w-full tw-p-3 ${personalEmailError
                    ? "tw-ring-1 tw-ring-red-500 tw-text-red-500"
                    : "tw-text-gray-700"
                    }`}
                >
                  <input
                    type={"text"}
                    value={personalEmail}
                    onChange={(e) => handlePersonalEmail(e.target.value)}
                    className={
                      "tw-rounded tw-bg-transparent tw-border-0 tw-full tw-flex-grow"
                    }
                  />
                  {Boolean(personalEmailVerification) ? (
                    <div className="tw-flex tw-items-center tw-ml-2 tw-text-green-500 tw-w-[14%]">
                      {personalEmailError ? (
                        <>
                          <div>
                            <img
                              src={"/images/circle-cross-red.svg"}
                              alt={"check"}
                              className="tw-pl-2"
                            />
                          </div>
                          <div className="tw-ml-1 tw-text-sm">Invalid</div>
                        </>
                      ) : (
                        <>
                          <div>
                            <img
                              src={"/images/circle-check-green.svg"}
                              alt={"check"}
                              className="tw-pl-2"
                            />
                          </div>
                          <div className="tw-ml-1 tw-text-sm">Verified</div>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {personalEmailVerification === 0 && personalEmail && (
                        <div className="tw-flex tw-items-center tw-w-[45%]">
                          {!personalEmailError && (
                            <button
                              type="button"
                              className="tw-rounded-full tw-bg-[#23426e] tw-border-0 tw-text-white tw-text-xs tw-py-1 tw-px-2 tw-cursor-pointer"
                              onClick={() => {
                                resendEmailOtp(personalEmail);
                              }}
                            >
                              Verify Now
                            </button>
                          )}

                          <div className="tw-flex tw-items-center tw-ml-2 tw-text-red-500">
                            <div className="tw-ml-2 tw-text-sm">
                              Not Verified
                            </div>
                            <div>
                              <img
                                src={"/images/circle-cross-red.svg"}
                                alt={"cross"}
                                className="tw-mx-2 pl-2"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="tw-flex tw-flex-col">
                <span className="tw-text-gray-500 ">
                  Phone Number
                  {!phoneVerification && (
                    <span className="tw-text-red-500 tw-ml-2">
                      {" "}
                      {phone.length >= 10 ? "Unverified" : "Invalid Phone"}{" "}
                    </span>
                  )}
                </span>

                <div
                  className={`update_profile_input_phone tw-flex tw-mx-2  tw-items-center tw-border-0  tw-focus:ring-0 tw-shadow-md  tw-flex-grow  tw-w-full ${phoneVerification
                    ? "tw-ring-1 tw-ring-red-500 tw-text-red-500"
                    : "tw-text-gray-700"
                    }`}
                >
                  <PhoneInput
                    // verified={phoneVerification}
                    country={"us"}
                    onlyCountries={acceptedCountriesPhone}
                    autoFormat
                    value={`${countryCode}${phone}`}
                    onChange={handlePhoneNumber}
                    buttonClass="bg-gray-100 border-0"
                    inputClass="tw-border-0 tw-ml-4 tw-p-3 tw-flex-grow tw-text-sm tw-outline-none tw-bg-white profile_phone_input"
                  />

                  {Boolean(phoneVerification) ? (
                    <div className="tw-flex tw-items-center  tw-text-green-500 tw-w-[14%]">
                      <div>
                        <img
                          src={"/images/circle-check-green.svg"}
                          alt={"check"}
                          className="tw-pl-2"
                        />
                      </div>
                      <div className="tw-mx-2 tw-text-sm">Verified</div>
                    </div>
                  ) : (
                    <>
                      {phoneVerification === 0 && phone && (
                        <div className="tw-flex tw-items-center tw-justify-end tw-w-full">
                          {phone.length >= 10 && (
                            <>
                              <button
                                type="button"
                                className="tw-rounded-full tw-bg-[#23426e] tw-border-0 tw-text-white tw-text-xs tw-py-1 tw-px-2 tw-cursor-pointer"
                                onClick={() => {
                                  resendMobileOtp();
                                }}
                              >
                                Verify Now
                              </button>

                            </>
                          )}

                          <div className="tw-flex tw-items-end tw-bg-red-50 tw-ml-2 tw-text-red-500">
                            <div className="tw-ml-2 tw-text-sm">
                              Not Verified
                            </div>
                            <div>
                              <img
                                src={"/images/circle-cross-red.svg"}
                                alt={"cross"}
                                className="tw-mx-2 pl-2"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="tw-flex tw-flex-col">
                <span className="tw-text-gray-500 ">
                  Airline Email (Optional)
                  {airlineEmailError && (
                    <span className="tw-text-red-500 tw-ml-2">Invalid</span>
                  )}
                </span>

                <div
                  className={`tw-flex tw-justify-start tw-items-center tw-rounded tw-shadow-md tw-w-full tw-p-3 ${airlineEmailError
                    ? "tw-ring-1 tw-ring-red-500 tw-text-red-500"
                    : "tw-text-gray-700"
                    }`}
                >
                  <input
                    // disabled={!editable}
                    type={"text"}
                    value={airlineEmail}
                    onChange={(e) => handleAirlineEmail(e.target.value)}
                    className={
                      " tw-rounded tw-bg-transparent tw-border-0 tw-full tw-flex-grow"
                    }
                  />
                  {Boolean(airlineEmailVerification) ? (
                    <div className="tw-flex tw-items-center tw-ml-2 tw-text-green-500 tw-w-[14%]">
                      {airlineEmailError ? (
                        <>
                          <div>
                            <img
                              src={"/images/circle-cross-red.svg"}
                              alt={"check"}
                              className="tw-pl-2"
                            />
                          </div>{" "}
                          { }
                          <div className="tw-ml-1 tw-text-sm tw-text-red-500">
                            Invalid
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <img
                              src={"/images/circle-check-green.svg"}
                              alt={"check"}
                              className="tw-pl-2"
                            />
                          </div>
                          <div className="tw-ml-1 tw-text-sm">Verified</div>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {airlineEmailVerification === 0 && airlineEmail && (
                        <div className="tw-flex tw-items-center tw-w-[45%]">
                          <button
                            type="button"
                            className="tw-rounded-full tw-bg-[#23426e] tw-border-0 tw-text-white tw-text-xs tw-py-1 tw-px-2 tw-cursor-pointer"
                            onClick={sendAirlineEmailVerificationLink}
                          >
                            Verify Now
                          </button>
                          <div className="tw-flex tw-items-center tw-ml-2 tw-text-red-500">
                            <div className="tw-ml-2 tw-text-sm ">
                              Not Verified
                            </div>
                            <div>
                              <img
                                src={"/images/circle-cross-red.svg"}
                                alt={"cross"}
                                className="tw-mx-2 pl-2"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <TextField
                verified={true}
                value={dob}
                placeholder=""
                type="date"
                setValue={setDob}
                label="Date of Birth"
                required
              />
            </div>

            {
              AppConfig.ENABLE_SELFIE_AND_ID_VERIFICATION_CHECKPOINT && <div className="tw-flex tw-flex-col">
                <div className="tw-flex tw-flex-col tw-justify-start tw-items-start">
                  <label className="tw-text-xs tw-mb-2">
                    {"Selfie & ID Verification"}
                  </label>
                  <div
                    className={`tw-flex tw-justify-start tw-items-center tw-w-full  tw-text-gray-700`}
                  >
                    {Boolean(user.idVerificationStatus) ? (
                      <div className="tw-flex tw-items-center tw-text-green-500 tw-w-[14%]">
                        <div>
                          <img
                            src={"/images/circle-check-green.svg"}
                            alt={"check"}
                            className="pl-2"
                          />
                        </div>
                        <div className="tw-ml-1 tw-text-sm">Verified</div>
                      </div>
                    ) : (
                      <div className="tw-flex tw-items-center tw-w-full">
                        <button
                          onClick={togglePersonaModal}
                          className="tw-p-2 tw-rounded-full tw-bg-[#23426e] tw-border-none tw-text-white tw-text-xs tw-px-4 tw-cursor-pointer"
                        >
                          Verify Now
                        </button>
                        <div className="tw-flex tw-items-center tw-text-red-500 tw-w-[22%]">
                          <div className="tw-ml-1 tw-text-sm">Not Verified</div>
                          <div>
                            <img
                              src={"/images/circle-cross-red.svg"}
                              alt={"cross"}
                              className="tw-pl-2"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }

            <button
              type="submit"
              className={`tw-w-max tw-rounded-md tw-py-2 tw-px-8 tw-bg-[#23426e] tw-border-0 ${AppConfig.ENABLE_SELFIE_AND_ID_VERIFICATION_CHECKPOINT ? 'tw-mt-16 update-btn' : ''}   tw-text-white tw-text-sm`}
              disabled={status}
            >
              {status ? "Updating ..." : "Update"}
            </button>
          </form>
          <div className=" tw-md:mr-32 tw-mt-4">
            <div className="tw-flex tw-items-center tw-p-4 tw-mb-4 tw-bg-gray-light tw-rounded">
              <BsFacebook size={32} className="tw-text-blue-500" />
              <p className="tw-text-sm tw-text-primary-blue tw-font-medium tw-mx-4">
                Facebook
              </p>
              <p className="tw-text-gray-400 tw-text-sm tw-mr-4">
                {user.facebookAuthentication ? (
                  <span className="tw-text-parrot">Connected</span>
                ) : (
                  "Not Connected"
                )}
              </p>

              <div className="tw-ml-auto">
                {!user.facebookAuthentication && (
                  <FacebookLogin
                    appId={constant.FACEBOOK_APPID}
                    callback={(e) => {
                      if (e.email) {
                        handleSocialAccountConnection({
                          email: e.email,
                          firstName: e.name.split(" ")[0],
                          lastName: e.name.split(" ")[1],
                          authType: "Facebook",
                          id: e.id,
                          dataKey: "facebookAuthentication",
                        });
                      } else toast.error("Something went wrong!");
                    }}
                    fields="name,email,picture,gender,birthday"
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        className="tw-border-0 tw-p-2 tw-px-4 tw-py-2 tw-rounded-md tw-text-white tw-bg-[#23426e] tw-text-sm"
                      >
                        Connect Now
                      </button>
                    )}
                  />
                )}
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-p-4  tw-bg-gray-light tw-rounded">
              <FcGoogle size={32} className="text-blue-500" />
              <p className="tw-text-sm tw-text-primary-blue tw-font-medium tw-mx-4">
                Google
              </p>
              <p className="tw-text-gray-400 tw-text-sm mr-4">
                {user.googleAuthentication ? (
                  <span className="tw-text-parrot">Connected</span>
                ) : (
                  "Not Connected"
                )}
              </p>
              <div className="tw-ml-auto">
                {!user.googleAuthentication && (
                  <GoogleLogin
                    clientId={constant.GOOGLE_CLIENTID}
                    onSuccess={(e) => {
                      if (e.profileObj.email) {
                        handleSocialAccountConnection({
                          email: e.profileObj.email,
                          firstName: e.profileObj.givenName,
                          lastName: e.profileObj.familyName,
                          id: e.profileObj.googleId,
                          authType: "Google",
                          dataKey: "googleAuthentication",
                        });
                      } else toast.error("Something went wrong!");
                    }}
                    onFailure={(e) => { }}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        className="tw-border-0 tw-p-2 tw-px-4 tw-py-2 tw-rounded-md tw-text-white tw-bg-[#23426e] tw-text-sm"
                      >
                        Connect Now
                      </button>
                    )}
                  />
                )}
              </div>
            </div>

            {/* <div className="tw-flex tw-items-center tw-p-4 mb-4 tw-bg-gray-light tw-rounded">
              <MdPermContactCalendar size={32} className="text-blue-500" />
              <p className="tw-text-sm tw-text-primary-blue tw-font-medium tw-mx-4">
                Selfie & Id Verification
              </p>
              <p className="tw-text-gray-400 tw-text-sm mr-4">
                {user.idVerificationStatus ? (
                  <span className="tw-text-parrot">Verified</span>
                ) : (
                  "Not Verified"
                )}
              </p>
              <div className="tw-ml-auto">
                {!user.idVerificationStatus && (
                  <button
                    onClick={togglePersonaModal}
                    className="tw-border-0 tw-p-2 tw-px-2 tw-rounded-md tw-text-white tw-bg-[#23426e] tw-text-xs"
                  >
                    Verify Now
                  </button>
                )}
              </div>
            </div> */}
          </div>
        </div>
      )}

      {emailModal && (
        <EmailVerificationModal
          toggleEmailModal={toggleEmailModal}
          emailVerification={emailVerification}
          resendEmailOtp={resendEmailOtp}
          verifyEmailOtp={verifyEmailOtp}
          personalEmail={personalEmail}
        />
      )}

      {phoneModal && (
        <PhoneVerificationModal
          resendMobileOtp={resendMobileOtp}
          togglePhoneModal={togglePhoneModal}
          verifyOtp={verifyOtp}
          phoneVerification={phoneVerification}
        />
      )}

      {personaModal && (
        <PersonaVerifyModal
          setPersonaModal={setPersonaModal}
          togglePersonaModal={togglePersonaModal}
          id={user.id}
          updateIdVerificationStatus={updateIdVerificationStatus}
        />
      )}
    </div>
  );
}

export default UpdateProfile;
