import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { IoCaretBackOutline, IoCaretForwardOutline } from "react-icons/io5";
import moment from 'moment';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

const ColdbedCustomCalender = ({ checkIn, getDayStyle, blockDateRange, blockedDates = [], currentMonth, setCurrentMonth, selectedDates, setSelectedDates, minDate, maxDate, onChange, }) => {
    const [hoverDate, setHoverDate] = useState(null);

    const isBeforeMinDate = (date) => minDate && date.isSameOrBefore(minDate, 'day');
    const isAfterMaxDate = (date) => maxDate && date.isAfter(maxDate, 'day');

    useEffect(() => {
        if (checkIn) {
            setSelectedDates([checkIn])
        }

    }, [])

    const isBlocked = (date) => {
        const blocked = blockedDates.find((blockedDate) =>
            dayjs(blockedDate.date).isSame(date, 'day')
        );
        return blocked && blocked.status === 'full-day';
    };

    const bookingStatus = (date) => {
        const blocked = blockedDates.find((blockedDate) => dayjs(blockedDate.date).isSame(date, 'day'));
        return blocked ? blocked.status : null;
    };

    const handleSelectDate = (date) => {

        const blockedDateList = blockDateRange() || []

        if (blockedDateList.includes(date.format('YYYY-MM-DD'))) {
            return
        }

        if (isBlocked(date) || isBeforeMinDate(date) || isAfterMaxDate(date)) return;
        setSelectedDates([date]);
    };

    const handleHoverDate = (date) => {
        setHoverDate(date);
    };

    const isInRange = (date) => {
        if (selectedDates.length !== 2) return false;
        const [startDate, endDate] = selectedDates;
        return date.isAfter(startDate) && date.isBefore(endDate);
    };

    const renderDate = (date) => {
        const isSelected = selectedDates.some((selectedDate) =>
            selectedDate.isSame(date, 'day')
        );
        const baseClass = 'tw-flex tw-h-10 tw-mb-1 tw-text-sm tw-text-[#1d2429] tw-items-center tw-justify-center tw-cursor-pointer tw-relative';
        const status = bookingStatus(date);
        const inRange = isInRange(date);
        const isHovered = hoverDate && hoverDate.isSame(date, 'day');
        const selectedClass = isSelected ? 'tw-bg-[rgb(35,66,110)] tw-rounded tw-text-white custom_rounded-10 ' : '';
        const blockedClass = (status === 'full-day' || isBeforeMinDate(date) || isAfterMaxDate(date))
            ? 'tw-bg-[#F8F8F8] tw-text-[#B3BDC3] tw-cursor-not-allowed'
            : '';
        const rangeClass = inRange ? 'tw-bg-blue-500' : '';
        const hoverClass = isHovered ? ' ' : '';

        return (
            <div
                key={date.format('YYYY-MM-DD')}
                className={`${baseClass}`}
                onClick={() => handleSelectDate(date)}
                onMouseEnter={() => handleHoverDate(date)}
                onMouseLeave={() => setHoverDate(null)}
            >
                <div style={{ ...getDayStyle(date.format('YYYY-MM-DD')) }} className={`tw-z-0 tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full ${selectedClass} ${blockedClass} ${rangeClass} ${hoverClass} ${isBeforeMinDate(date) ? 'tw-bg-gray-100' : ''}`}>
                    <span className="tw-z-20">{date.date()}</span>
                </div>
            </div>
        );
    };

    const generateCalendar = () => {
        const startOfMonth = currentMonth.startOf('month').day();
        const daysInMonth = currentMonth.daysInMonth();
        const dates = Array.from({ length: startOfMonth }, () => null);

        for (let day = 1; day <= daysInMonth; day++) {
            dates.push(currentMonth.date(day));
        }

        return dates.concat(Array(42 - dates.length).fill(null));
    };

    const renderCalendar = () => {
        const dates = generateCalendar();
        return (
            <div className="tw-grid tw-grid-cols-7">
                {dates.map((date, index) =>
                    date ? renderDate(date) : <div key={index} />
                )}
            </div>
        );
    };

    const handlePreviousMonth = () => {
        const newMonth = currentMonth.subtract(1, 'month');
        if (newMonth.isBefore(dayjs(), 'month')) {
            return;
        }
        setCurrentMonth(newMonth);
    };

    const handleNextMonth = () => {
        const newMonth = currentMonth.add(1, 'month');
        if (maxDate && newMonth.isAfter(maxDate, 'month')) {
            return;
        }
        setCurrentMonth(newMonth);
    };

    useEffect(() => {
        if (onChange) {
            onChange(selectedDates)
        }

    }, [selectedDates])
    return (
        <div className="tw-w-full">
            <div
                style={{
                    display: "flex",
                    padding: "0.5rem",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    flexDirection: "row",
                    gap: "0.5rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    background: "#eff2f7",
                }}>

                <div
                    className={`tw-border tw-flex-1 tw-text-center tw-bg-white tw-text-sm tw-rounded tw-p-2 tw-cursor-pointer`}
                    style={{ flex: 1 }}
                >
                    {selectedDates && selectedDates.length > 0 ? moment(selectedDates[0].toDate()).format('MMM DD, YYYY') : 'MM DD, YYYY'}
                </div>


            </div>
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                <div onClick={handlePreviousMonth} className=" tw-cursor-pointer tw-bg-gray-100 tw-hover:tw-bg-gray-200 tw-ml-0 tw-p-1 tw-rounded">
                    <IoCaretBackOutline className='text-black' />
                </div>
                <h3 className="tw-text-lg">{currentMonth.format('MMMM YYYY')}</h3>
                <div onClick={handleNextMonth} className=" tw-cursor-pointer tw-bg-gray-100 tw-hover:tw-bg-gray-200 tw-ml-0 tw-p-1 tw-rounded">
                    <IoCaretForwardOutline className='text-black' />
                </div>
            </div>

            <div className="tw-gap-2 tw-grid tw-grid-cols-7 tw-mb-2 tw-border">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                    <div key={day} className="tw-text-center">{day}</div>
                ))}
            </div>

            <div>{renderCalendar()}</div>
        </div>
    );
};

export default ColdbedCustomCalender;
