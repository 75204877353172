import React, { useEffect, useState, useRef, useMemo } from 'react'
import useQuery from '../../hooks/useQuery'
import { propertyService } from '../../_services'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {
    generateDateRange,
    getBookedDatesForColdBed,
    getManualBlockedDatesForColdBed,
    getMinimumDateToDisableCalenderForColdBed,
    isDisabledAutoBlockedDates,
} from '../../_helpers/helpers'
import RoomAndBedSelection from './RoomAndBedSelection'
import BlockUnblockButton from './BlockUnblockButton'
import { COLD_BED, CRASHPAD, ENTIRE_PLACE } from '../../_helpers/constants'
import { FixedDate, generateDateRangeUTC } from '../../_helpers/DateUTCHelpers'
import ColdbedCustomCalender from './ColdbedCustomCalender'
import dayjs from 'dayjs'

export default function ColdBedContainer({ fetchPropertyDetails, properyDetails, selectedRoom, selectedBed, setSelectedBed, handleRoomSelection }) {
    const bookingType = COLD_BED
    const query = useQuery()
    const today = new Date()
    const [currentMonth, setCurrentMonth] = useState(dayjs())
    const [selectedDate, setSelectedDate] = useState([]);
    const [isBlocking, setIsBlockingOrUnblocking] = useState(true)
    const [coldBedMinimumDisableDate, setColdBedMinimumDisableDate] = useState(new Date())

    const [coldbedSelectedBlockingDate, setColdbedSelectedBlockingDate] = useState(new Date())

    const bedsContainer = useRef()

    const scrollLeftHandlerForBed = () => {
        bedsContainer.current.scrollTo({
            left: bedsContainer.current.scrollLeft - 88,
            behavior: 'smooth',
        })
    }

    const scrollRightHandlerForBed = () => {
        bedsContainer.current.scrollTo({
            left: bedsContainer.current.scrollLeft + 88,
            behavior: 'smooth',
        })
    }

    const confirmBlockUnblock = () => {

        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const startDate = moment(selectedDate[0].toDate()).startOf('day')

            const selectedDateForBlock = FixedDate(coldbedSelectedBlockingDate)

            

            if (selectedDateForBlock <= coldBedMinimumDisableDate) {
                toast.error('You cannot block dates that are equal to or after any booked dates!')
                return
            }
           

            if (getBookedDatesForColdBed(selectedBed).includes(coldbedSelectedBlockingDate)) {
                toast.error('You cannot block dates that overlap with or are the same as any booked dates!')
                return
            }

            const bedDetails = {
                bookingType,
                propertyId: properyDetails._id,
                listingId: properyDetails.listingId,
                hostId: properyDetails.addedBy,
                selectedRoom: selectedRoom.roomDetails._id,
                selectedBed: selectedBed._id,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: startDate.format('YYYY-MM-DD'),
                propertyType: properyDetails.rentalType,
                isBlocking,
                coldBedblockedDate: moment(coldbedSelectedBlockingDate).format('YYYY-MM-DD'),
            }

            propertyService
                .blockUnblockedBeds(bedDetails)
                .then(res => {
                    if (res.statusCode === 200) {
                        toast.success(isBlocking ? 'Blocked Successfully!' : 'Unblocked Successfully!')
                        fetchPropertyDetails(properyDetails._id, { bookingType }, false)
                    }
                })
                .catch(() => {
                    toast.error('Something went wrong while fetching property details')
                })
        }

        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const startDate = moment(selectedDate[0].toDate()).startOf('day')

            const selectedDateForBlock = FixedDate(coldbedSelectedBlockingDate)

            if (selectedDateForBlock <= coldBedMinimumDisableDate) {
                toast.error('You cannot block dates that are equal to or after any booked dates!')
                return
            }

            if (getBookedDatesForColdBed(selectedRoom.roomDetails).includes(coldbedSelectedBlockingDate)) {
                toast.error('You cannot block dates that overlap with or are the same as any booked dates!')
                return
            }

            const bedDetails = {
                bookingType,
                propertyId: properyDetails._id,
                listingId: properyDetails.listingId,
                hostId: properyDetails.addedBy,
                selectedRoom: selectedRoom.roomDetails._id,
                selectedBed: null,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: startDate.format('YYYY-MM-DD'),
                propertyType: properyDetails.rentalType,
                isBlocking,
                coldBedblockedDate: moment(coldbedSelectedBlockingDate).format('YYYY-MM-DD'),
            }

            propertyService
                .blockUnblockedBeds(bedDetails)
                .then(res => {
                    if (res.statusCode === 200) {
                        toast.success(isBlocking ? 'Blocked Successfully!' : 'Unblocked Successfully!')
                        fetchPropertyDetails(properyDetails._id, { bookingType }, false)
                    }
                })
                .catch(() => {
                    toast.error('Something went wrong while fetching property details')
                })
        }
    }

    useEffect(() => {
        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const disabled = getMinimumDateToDisableCalenderForColdBed(selectedBed)

            setColdBedMinimumDisableDate(moment(disabled).toDate())

            const ckDate = getBlockedDateRange() || []
            
            if (ckDate.length > 0) {
                const lastDate = moment(ckDate[ckDate.length - 1]).add(1, 'day').toDate()
                setColdbedSelectedBlockingDate(lastDate)
                setSelectedDate([dayjs(lastDate)])
                return
            }
            setSelectedDate([dayjs(disabled).add(1,'day')])
            setColdbedSelectedBlockingDate(disabled)
        }

        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const disabled = getMinimumDateToDisableCalenderForColdBed(selectedRoom.roomDetails)


            setColdBedMinimumDisableDate(moment(disabled).toDate())

            const ckDate = getBlockedDateRange() || []
            
            if (ckDate.length > 0) {
                const lastDate = moment(ckDate[ckDate.length - 1]).add(1, 'day').toDate()
                setColdbedSelectedBlockingDate(lastDate)
                setSelectedDate([dayjs(lastDate)])
                return
            }
            setSelectedDate([dayjs(disabled).add(1,'day')])
            setColdbedSelectedBlockingDate(disabled)
        }
    }, [selectedBed, bookingType, selectedRoom, properyDetails])


    const getBlockedDateRange = () => {
        if (properyDetails && properyDetails.rentalType === 'Crashpad' && selectedBed) {
            const blockedDates = getManualBlockedDatesForColdBed(selectedBed) || []
            const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const blockRanges = (sortedBlockedDates.length > 0 && generateDateRangeUTC(sortedBlockedDates[0], sortedBlockedDates[sortedBlockedDates.length - 1])) || []
            return blockRanges;
        } else if (properyDetails && properyDetails.rentalType === 'Entire Place' && selectedRoom) {
            const blockedDates = getManualBlockedDatesForColdBed(selectedRoom.roomDetails) || []
            const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const blockRanges = (sortedBlockedDates.length > 0 && generateDateRangeUTC(sortedBlockedDates[0], sortedBlockedDates[sortedBlockedDates.length - 1])) || []

            return blockRanges
        }

    }

    const blockedDateRangeMinusOne = () => {
        const range = getBlockedDateRange() || []
        if (range.length >= 1) {
            return range.slice(0, -1)
        } else {
            return []
        }

    }

    const getDayStyle = useMemo(
        () => date => {
            if (properyDetails && properyDetails.rentalType === 'Crashpad' && selectedBed && selectedDate && selectedDate.length) {
                const dateStr = moment(date).format('YYYY-MM-DD')
                const blockedDates = getManualBlockedDatesForColdBed(selectedBed) || []
                const bookedDates = getBookedDatesForColdBed(selectedBed) || []
                const autoBlockedDates = []

                const selectedStartDateStr = selectedDate && moment(selectedDate[0].toDate()).format('YYYY-MM-DD')
                const selectedEndDateStr = selectedDate && moment(selectedDate[0].toDate()).format('YYYY-MM-DD')

                const individualDate = FixedDate(dateStr)
                const selectedStartDate = FixedDate(selectedStartDateStr)
                const selectedEndDate = FixedDate(selectedEndDateStr)
                const isWithinSelectedRange = bookingType !== 'Cold bed' && individualDate >= selectedStartDate && individualDate <= selectedEndDate

                const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
                const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
                const sortedAutoBlockedDates = autoBlockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

                if (isWithinSelectedRange) {
                    return {}
                } else {
                    const blockRanges = (sortedBlockedDates.length > 0 && generateDateRangeUTC(sortedBlockedDates[0], sortedBlockedDates[sortedBlockedDates.length - 1])) || []

                    const bookedRange =
                        (sortedBookedDates.length > 0 && generateDateRange(sortedBookedDates[0], sortedBookedDates[sortedBookedDates.length - 1])) ||
                        []
                    const autoBlockedRage = (sortedAutoBlockedDates.length > 0 && sortedAutoBlockedDates) || []
                    if (autoBlockedRage.includes(dateStr)) {
                        return { width: '100%', background: 'rgb(243 244 246)', color: 'rgb(150 155 160)', zIndex: '0' }
                    }

                    if (bookedRange.includes(dateStr)) {
                        return { width: '100%', background: 'rgb(243 244 246)', color: 'rgb(150 155 160)', zIndex: '0' }
                    }

                    if (blockRanges[blockRanges.length - 1] === dateStr && !autoBlockedRage.includes(dateStr) && !bookedRange.includes(dateStr)) {
                        return { width: '100%', background: '#f48889', color: 'white', zIndex: '0', borderRadius: '10px' }
                    }

                    if (blockRanges.includes(dateStr) && !autoBlockedRage.includes(dateStr) && !bookedRange.includes(dateStr)) {
                        return { width: '100%', background: 'rgb(243 244 246)', color: 'rgb(150 155 160)', zIndex: '0' }
                    }
                }
            }
            if (properyDetails && properyDetails.rentalType === 'Entire Place' && selectedRoom && selectedDate && selectedDate.length) {
                const dateStr = moment(date).format('YYYY-MM-DD')
                const blockedDates = getManualBlockedDatesForColdBed(selectedRoom.roomDetails) || []
                const bookedDates = getBookedDatesForColdBed(selectedRoom.roomDetails) || []
                const autoBlockedDates = []

                const selectedStartDateStr = selectedDate && moment(selectedDate[0].toDate()).format('YYYY-MM-DD')
                const selectedEndDateStr = selectedDate && moment(selectedDate[0].toDate()).format('YYYY-MM-DD')

                const individualDate = FixedDate(dateStr)
                const selectedStartDate = FixedDate(selectedStartDateStr)
                const selectedEndDate = FixedDate(selectedEndDateStr)
                const isWithinSelectedRange = bookingType !== COLD_BED && individualDate >= selectedStartDate && individualDate <= selectedEndDate

                const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

                const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
                const sortedAutoBlockedDates = autoBlockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

                if (isWithinSelectedRange) {
                    return {
                        zIndex: '-1',
                        color: 'white',
                    }
                } else {
                    const blockRanges =
                        (sortedBlockedDates.length > 0 &&
                            generateDateRangeUTC(sortedBlockedDates[0], sortedBlockedDates[sortedBlockedDates.length - 1])) ||
                        []
                    const bookedRange =
                        (sortedBookedDates.length > 0 &&
                            generateDateRangeUTC(sortedBookedDates[0], sortedBookedDates[sortedBookedDates.length - 1])) ||
                        []
                    const autoBlockedRage = (sortedAutoBlockedDates.length > 0 && sortedAutoBlockedDates) || []
                    if (autoBlockedRage.includes(dateStr)) {
                        return { width: '100%', background: 'rgb(243 244 246)', color: 'rgb(150 155 160)', zIndex: '0' }
                    }

                    if (bookedRange.includes(dateStr)) {
                        return { width: '100%', background: 'rgb(243 244 246)', color: 'rgb(150 155 160)', zIndex: '0' }
                    }

                    if (blockRanges[blockRanges.length - 1] === dateStr && !autoBlockedRage.includes(dateStr) && !bookedRange.includes(dateStr)) {
                        return { width: '100%', background: '#f48889', color: 'white', zIndex: '0', borderRadius: '10px' }
                    }

                    if (blockRanges.includes(dateStr) && !autoBlockedRage.includes(dateStr) && !bookedRange.includes(dateStr)) {
                        return { width: '100%', background: 'rgb(243 244 246)', color: 'rgb(150 155 160)', zIndex: '0' }
                    }
                }
            }
        },
        [bookingType, selectedDate, selectedBed, selectedRoom]
    )

    const updateToUnblock = date => {
        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const blockedDates = getManualBlockedDatesForColdBed(selectedBed) || []
            const bookedDates = getBookedDatesForColdBed(selectedBed) || []
            const autoBlockedDates = isDisabledAutoBlockedDates(selectedBed) || []

            const dateStr = moment(date).format('YYYY-MM-DD')
            const individualDate = FixedDate(dateStr)

            const coldBedSelected = FixedDate(coldbedSelectedBlockingDate)

            const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const sortedAutoBlockedDates = autoBlockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const blocRanges =
                (sortedBlockedDates.length > 0 && generateDateRange(sortedBlockedDates[0], sortedBlockedDates[sortedBlockedDates.length - 2])) || []

            if (sortedAutoBlockedDates.includes(dateStr) || blocRanges.includes(dateStr) || sortedBookedDates.includes(dateStr)) {
                return false
            }

            if (sortedBlockedDates.includes(dateStr) || moment(individualDate).isSame(moment(coldBedSelected), 'day')) {
                return true
            }

            return false
        }
        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const blockedDates = getManualBlockedDatesForColdBed(selectedRoom.roomDetails) || []
            const bookedDates = getBookedDatesForColdBed(selectedRoom.roomDetails) || []
            const autoBlockedDates = isDisabledAutoBlockedDates(selectedRoom.roomDetails) || []

            const dateStr = moment(date).format('YYYY-MM-DD')
            const individualDate = FixedDate(dateStr)

            const coldBedSelected = FixedDate(coldbedSelectedBlockingDate)

            const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const sortedAutoBlockedDates = autoBlockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

            const blocRanges =
                (sortedBlockedDates.length > 0 && generateDateRange(sortedBlockedDates[0], sortedBlockedDates[sortedBlockedDates.length - 2])) || []

            if (sortedAutoBlockedDates.includes(dateStr) || blocRanges.includes(dateStr) || sortedBookedDates.includes(dateStr)) {
                return false
            }

            if (sortedBlockedDates.includes(dateStr) || moment(individualDate).isSame(moment(coldBedSelected), 'day')) {
                return true
            }

            return false
        }
    }

    const handleOnChangeColdbedDates = coldBedBlockDate => {
        if (!coldBedBlockDate) {
            return
        }

        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const manualBlockedDateList = getManualBlockedDatesForColdBed(selectedBed, true) || []
            const startDate = moment(coldBedBlockDate).format('YYYY-MM-DD')

            const isStartDateBlocked = manualBlockedDateList.includes(startDate)

            if (isStartDateBlocked) {
                setIsBlockingOrUnblocking(!isStartDateBlocked)

                setColdbedSelectedBlockingDate(coldBedBlockDate)
            } else {
                setIsBlockingOrUnblocking(!isStartDateBlocked)
                setColdbedSelectedBlockingDate(coldBedBlockDate)
            }
        }
        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const manualBlockedDateList = getManualBlockedDatesForColdBed(selectedRoom.roomDetails, true) || []

            const startDate = moment(coldBedBlockDate).format('YYYY-MM-DD')

            const isStartDateBlocked = manualBlockedDateList.includes(startDate)

            if (isStartDateBlocked) {
                setIsBlockingOrUnblocking(!isStartDateBlocked)

                setColdbedSelectedBlockingDate(coldBedBlockDate)
            } else {
                setIsBlockingOrUnblocking(!isStartDateBlocked)
                setColdbedSelectedBlockingDate(coldBedBlockDate)
            }
        }
    }

    function getDisabledDates() {
        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const list = getBookedDatesForColdBed(selectedBed) || []
            const listToDate = list.map(item => ({ date: FixedDate(item), status: 'full-day' }))

            return listToDate
        }
        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const list = getBookedDatesForColdBed(selectedRoom.roomDetails) || []
            const listToDate = list.map(item => ({ date: FixedDate(item), status: 'full-day' }))

            return listToDate
        }
        return []
    }


    useEffect(() => {
        if (selectedDate && selectedDate[0]) {
            handleOnChangeColdbedDates(selectedDate[0].toDate())
        }
    }, [selectedDate])

    return (
        <>
            <div className="tw-my-4">
                <div className="tw-mx-auto tw-w-max">
                    <ColdbedCustomCalender
                        currentMonth={currentMonth}
                        setCurrentMonth={setCurrentMonth}
                        minDate={coldBedMinimumDisableDate}
                        maxDate={moment().add(1, 'month').endOf('month').toDate()}
                        blockedDates={getDisabledDates()}
                        selectedDates={selectedDate}
                        setSelectedDates={setSelectedDate}
                        checkIn={dayjs(coldbedSelectedBlockingDate)}
                        onChange={() => { }}
                        getDayStyle={getDayStyle}
                        blockDateRange={blockedDateRangeMinusOne}

                    />
                </div>
            </div>

            <RoomAndBedSelection
                bedsContainer={bedsContainer}
                handleRoomSelection={handleRoomSelection}
                properyDetails={properyDetails}
                scrollLeftHandlerForBed={scrollLeftHandlerForBed}
                scrollRightHandlerForBed={scrollRightHandlerForBed}
                selectedRoom={selectedRoom}
                selectedBed={selectedBed}
                setSelectedBed={setSelectedBed}
            />
            {properyDetails && (
                <BlockUnblockButton
                    properyDetails={properyDetails}
                    selectedRoom={selectedRoom}
                    confirmBlockUnblock={confirmBlockUnblock}
                    isBlocking={isBlocking}
                    selectedBed={selectedBed}
                />
            )}
        </>
    )
}
