import { DateRange } from 'react-date-range'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import useQuery from '../../hooks/useQuery'
import { propertyService } from '../../_services'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import dayjs from 'dayjs'
import {
    checkInitialDate, getBookedDatesForHotbed, getHotbedInitialVisitDate, getHotbedMaxDate, getManualBlockedDatesForHotbed,
    isDateInRangeBlocked, splitIntoRanges
} from '../../_helpers/helpers'

import RoomAndBedSelection from './RoomAndBedSelection'
import BlockUnblockButton from './BlockUnblockButton'
import { CRASHPAD, ENTIRE_PLACE, HOT_BED } from '../../_helpers/constants'
import { FixedDate } from '../../_helpers/DateUTCHelpers'
import HotbedCustomCalender from './HotbedCustomCalender'

export default function HotBedContainer({ properyDetails, fetchPropertyDetails, selectedRoom, selectedBed, setSelectedBed, handleRoomSelection }) {
    const query = useQuery()
    const today = new Date()
    const bookingType = HOT_BED
    const [isBlocking, setIsBlockingOrUnblocking] = useState(true)
    const [hotbedMaxDate, setHotbedMaxDate] = useState(new Date(today.getFullYear(), today.getMonth() + 2, 0))
    const [currentMonth, setCurrentMonth] = useState(dayjs())
    const [selectedDate, setSelectedDate] = useState([]);

    const [visitDate, setVisitDate] = useState([
        {
            startDate: checkInitialDate(moment(new Date()).toDate()),
            endDate: checkInitialDate(moment(new Date()).toDate()),
            key: 'selection',
        },
    ])

    const bedsContainer = useRef()

    const scrollLeftHandlerForBed = () => {
        bedsContainer.current.scrollTo({
            left: bedsContainer.current.scrollLeft - 88,
            behavior: 'smooth',
        })
    }

    const scrollRightHandlerForBed = () => {
        bedsContainer.current.scrollTo({
            left: bedsContainer.current.scrollLeft + 88,
            behavior: 'smooth',
        })
    }

    const confirmBlockUnblock = () => {
        if (properyDetails && properyDetails.rentalType === CRASHPAD) {
            const startDate = moment(visitDate[0].startDate).startOf('day');
            const endDate = moment(visitDate[0].endDate).endOf('day');

            const bookedDates = selectedBed.hotbedBookedDates.length > 0 ? selectedBed.hotbedBookedDates.map(date => moment(date).startOf('day')) : [];

            const isDateAfterOrEqual = bookedDates.some(
                bookedDate => startDate.isSameOrAfter(bookedDate, 'day') || endDate.isSameOrAfter(bookedDate, 'day')
            );

            const maxDate = moment().add(1, 'month').endOf('month')

            if (moment(startDate).isAfter(maxDate)) {
                return toast.info(`You cannot block date after ${maxDate.format('MMM DD, YYYY')}.`)
            }

            const isDateOverlapping = bookedDates.some(bookedDate => bookedDate.isBetween(startDate, endDate, 'day', '[)'));

            if (isDateOverlapping && bookingType === 'Hot bed') {
                toast.error(isDateAfterOrEqual ? 'You cannot block dates that are equal to or after any booked dates!' :
                    'You cannot block dates that overlap with or are the same as any booked dates!');
                return;
            }

            const bedDetails = {
                bookingType,
                propertyId: properyDetails._id,
                listingId: properyDetails.listingId,
                hostId: properyDetails.addedBy,
                selectedRoom: selectedRoom.roomDetails._id,
                selectedBed: selectedBed._id,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                propertyType: properyDetails.rentalType,
                isBlocking,

            };

            propertyService.blockUnblockedBeds(bedDetails)
                .then(res => {
                    if (res.statusCode === 200) {
                        toast.success(isBlocking ? 'Blocked Successfully!' : 'Unblocked Successfully!');
                        fetchPropertyDetails(properyDetails._id, { bookingType }, false);
                    }
                })
                .catch(() => {
                    toast.error('Something went wrong while fetching property details');
                });
        }

        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const startDate = moment(visitDate[0].startDate).startOf('day');
            const endDate = moment(visitDate[0].endDate).endOf('day');

            const bookedDates = selectedRoom.roomDetails.hotbedBookedDates || []

            const isDateAfterOrEqual = bookedDates.some(
                bookedDate => startDate.isSameOrAfter(bookedDate, 'day') || endDate.isSameOrAfter(bookedDate, 'day')
            );

            const isDateOverlapping = bookedDates.some(bookedDate => bookedDate.isBetween(startDate, endDate, 'day', '[)'));

            if (isDateOverlapping && bookingType === HOT_BED) {
                toast.error(isDateAfterOrEqual ? 'You cannot block dates that are equal to or after any booked dates!' :
                    'You cannot block dates that overlap with or are the same as any booked dates!');
                return;
            }

            const bedDetails = {
                bookingType,
                propertyId: properyDetails._id,
                listingId: properyDetails.listingId,
                hostId: properyDetails.addedBy,
                selectedRoom: selectedRoom.roomDetails._id,
                selectedBed: null,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                propertyType: properyDetails.rentalType,
                isBlocking,
            };

            propertyService.blockUnblockedBeds(bedDetails)
                .then(res => {
                    if (res.statusCode === 200) {
                        toast.success(isBlocking ? 'Blocked Successfully!' : 'Unblocked Successfully!');
                        fetchPropertyDetails(properyDetails._id, { bookingType }, false);
                    }
                })
                .catch(() => {
                    toast.error('Something went wrong while fetching property details');
                });
        }

    };


    useEffect(() => {
        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            setHotbedMaxDate(getHotbedMaxDate(selectedBed))
            const visitDate = getHotbedInitialVisitDate(selectedBed)
            setVisitDate(visitDate)
            setSelectedDate([dayjs(visitDate[0].startDate)])
        }
        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            setHotbedMaxDate(getHotbedMaxDate(selectedRoom.roomDetails))
            const visitDate = getHotbedInitialVisitDate(selectedRoom.roomDetails)
            setVisitDate(visitDate)
            setSelectedDate([dayjs(visitDate[0].startDate)])
        }

    }, [selectedBed, bookingType, selectedRoom, properyDetails])

    const getDayStyle = useMemo(
        () => date => {
            if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
                const dateStr = moment(date).format('YYYY-MM-DD')
                const blockedDates = getManualBlockedDatesForHotbed(selectedBed)
                const bookedDates = getBookedDatesForHotbed(selectedBed)


                const selectedStartDateStr = visitDate && moment(visitDate[0].startDate).format('YYYY-MM-DD')
                const selectedEndDateStr = visitDate && moment(visitDate[0].endDate).format('YYYY-MM-DD')

                const individualDate = FixedDate(dateStr)
                const selectedStartDate = FixedDate(selectedStartDateStr)
                const selectedEndDate = FixedDate(selectedEndDateStr)
                const isWithinSelectedRange = bookingType !== 'Cold bed' && individualDate >= selectedStartDate && individualDate <= selectedEndDate

                const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
                const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()



                if (isWithinSelectedRange) {
                    return {}
                } else {

                    const blockRanges = sortedBlockedDates.length > 0 ? splitIntoRanges(sortedBlockedDates) : []
                    const bookedRanges = sortedBookedDates.length > 0 ? splitIntoRanges(sortedBookedDates) : []

                    for (const bookedRange of bookedRanges) {
                        if (bookedRange.includes(dateStr)) {
                            return { width: '100%', background: 'rgb(243 244 246)', color: 'rgb(150 155 160)', zIndex: '0' }

                        }
                    }
                    for (const _range of blockRanges) {
                        const today = moment().startOf('day');
                        let range = _range.filter(date => moment(date).isSameOrAfter(today, 'day'));

                        if (range.includes(dateStr)) {
                            let isSingle = range.length === 1;
                            let isStart = dateStr === range[0];
                            let isEnd = dateStr === range[range.length - 1];
                            const isSaturday = dayjs(dateStr).day() === 6;
                            const isSunday = dayjs(dateStr).day() === 0;
                            const isEndOfMonth = dayjs(dateStr).isSame(dayjs(dateStr).endOf('month'), 'day');
                            const isStartOfMonth = dayjs(dateStr).isSame(dayjs(dateStr).startOf('month'), 'day');

                            if (isSaturday) {
                                isEnd = true
                            }
                            if (isSunday) {
                                isStart = true
                            }

                            const isOlderThanToday = moment(dateStr).isBefore(new Date(), 'day')

                            if (isOlderThanToday) {
                                return {}
                            }

                            let borderRadius = "";

                            if (isSingle) {
                                borderRadius = '18px'
                            } else if (isStart) {
                                borderRadius = isEndOfMonth? '18px': '18px 0px 0px 18px'
                            } else if (isEnd) {
                                borderRadius = isStartOfMonth? '18px': "0px 18px 18px 0px"
                            }

                            if (isSaturday) {
                                borderRadius = isStart? '18px': "0px 18px 18px 0px"
                            }

                            if (isSunday) {
                                borderRadius = isEnd? "18px": '18px 0px 0px 18px'
                            }

                            if(isStartOfMonth && !isEnd){
                                borderRadius="18px 0px 0px 18px"
                            }
                            return {
                                width: '100%', background: '#f48889', color: 'white', zIndex: 1,
                                maxHeight: '1.75rem',
                                borderRadius: borderRadius,
                            };
                        }
                    }
                }
            } else if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
                const dateStr = moment(date).format('YYYY-MM-DD')
                const blockedDates = getManualBlockedDatesForHotbed(selectedRoom.roomDetails); // isDisableBlockedDate(selectedBed) || []
                const bookedDates = getBookedDatesForHotbed(selectedRoom.roomDetails) // isDisabledBookedDates(selectedBed) || []

                const selectedStartDateStr = visitDate && moment(visitDate[0].startDate).format('YYYY-MM-DD')
                const selectedEndDateStr = visitDate && moment(visitDate[0].endDate).format('YYYY-MM-DD')

                const individualDate = FixedDate(dateStr)
                const selectedStartDate = FixedDate(selectedStartDateStr)
                const selectedEndDate = FixedDate(selectedEndDateStr)
                const isWithinSelectedRange = bookingType !== 'Cold bed' && individualDate >= selectedStartDate && individualDate <= selectedEndDate

                const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
                const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

                if (isWithinSelectedRange) {
                    return {}
                } else {

                    const blockRanges = sortedBlockedDates.length > 0 ? splitIntoRanges(sortedBlockedDates) : []
                    const bookedRanges = sortedBookedDates.length > 0 ? splitIntoRanges(sortedBookedDates) : []

                    for (const bookedRange of bookedRanges) {
                        if (bookedRange.includes(dateStr)) {
                            return { width: '100%', background: 'rgb(243 244 246)', color: 'rgb(150 155 160)', zIndex: '0' }
                        }
                    }

                    for (const _range of blockRanges) {
                        const today = moment().startOf('day');
                        let range = _range.filter(date => moment(date).isSameOrAfter(today, 'day'));
                        if (range.includes(dateStr)) {
                            let isSingle = range.length === 1;
                            let isStart = dateStr === range[0];
                            let isEnd = dateStr === range[range.length - 1];
                            let isSaturday = dayjs(dateStr).day() === 6;
                            let isSunday = dayjs(dateStr).day() === 0;
                            let isEndOfMonth = dayjs(dateStr).isSame(dayjs(dateStr).endOf('month'), 'day');
                            let isStartOfMonth = dayjs(dateStr).isSame(dayjs(dateStr).startOf('month'), 'day');

                            if (isSaturday) {
                                isEnd = true
                            }
                            if (isSunday) {
                                isStart = true
                            }

                            const isOlderThanToday = moment(dateStr).isBefore(new Date(), 'day')

                            if (isOlderThanToday) {
                                return {}
                            }

                            let borderRadius = "";

                            if (isSingle) {
                                borderRadius = '18px'
                            } else if (isStart) {
                                borderRadius = isEndOfMonth? '18px': '18px 0px 0px 18px'
                            } else if (isEnd) {
                                borderRadius = isStartOfMonth? '18px': "0px 18px 18px 0px"
                            }

                            if (isSaturday) {
                                borderRadius = isStart? '18px': "0px 18px 18px 0px"
                            }

                            if (isSunday) {
                                borderRadius = isEnd? "18px": '18px 0px 0px 18px'
                            }

                            if(isStartOfMonth && !isEnd){
                                borderRadius="18px 0px 0px 18px"
                            }

                            return {
                                width: '100%', background: '#f48889', color: 'white', zIndex: '0', maxHeight: '1.75rem',
                                borderRadius: borderRadius,
                            };
                        }
                    }
                }
            }
        },
        [bookingType, visitDate, selectedBed, selectedRoom]
    )

    const handleOnChangeDates = e => {

        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const blockedDates = getManualBlockedDatesForHotbed(selectedBed)

            if (!e.selection || !e.selection.startDate || !e.selection.endDate) {
                return
            }
            const bothDatesBlocked = isDateInRangeBlocked(e.selection.startDate, e.selection.endDate, blockedDates)
            setIsBlockingOrUnblocking(!bothDatesBlocked)

            setVisitDate([
                {
                    startDate: new Date(e.selection.startDate),
                    endDate: new Date(e.selection.endDate),
                    key: 'selection',
                },
            ])
        }
        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const blockedDates = getManualBlockedDatesForHotbed(selectedRoom.roomDetails)

            if (!e.selection || !e.selection.startDate || !e.selection.endDate) {
                return
            }
            const bothDatesBlocked = isDateInRangeBlocked(e.selection.startDate, e.selection.endDate, blockedDates)
            setIsBlockingOrUnblocking(!bothDatesBlocked)

            setVisitDate([
                {
                    startDate: new Date(e.selection.startDate),
                    endDate: new Date(e.selection.endDate),
                    key: 'selection',
                },
            ])
        }

    }


    function getDisabledDates() {

        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const list = getBookedDatesForHotbed(selectedBed) || []
            const listToDate = list.map(item => ({ date: FixedDate(item), status: 'full-day' }))
            return listToDate;
        }
        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const list = getBookedDatesForHotbed(selectedRoom.roomDetails) || []
            const listToDate = list.map(item => ({ date: FixedDate(item), status: 'full-day' }))
            return listToDate;
        }

        return []
    }


    const onHotbedCalenderChange = (item) => {
        let e = {}
        if (item && item.length === 1) {
            const startDate = item[0].toDate();
            const endDate = item[0].toDate()
            e = {
                selection: {
                    startDate,
                    endDate,
                    key: 'selection'
                }
            }

        } else if (item && item.length === 2) {
            const startDate = item[0].toDate();
            const endDate = item[1].toDate();
            e = {
                selection: {
                    startDate,
                    endDate,
                    key: 'selection'
                }
            }
        }
        handleOnChangeDates(e)
    }

    useEffect(() => {
        if (selectedDate) {
            onHotbedCalenderChange(selectedDate)
        }
    }, [selectedDate])


    return (
        <>
            <div className="tw-my-4">
                <div className="tw-mx-auto tw-w-max">
                    <HotbedCustomCalender
                        minDate={new Date()}
                        getDayStyle={getDayStyle}
                        maxDate={hotbedMaxDate}
                        currentMonth={currentMonth}
                        setCurrentMonth={setCurrentMonth}
                        selectedDates={selectedDate}
                        setSelectedDates={setSelectedDate}
                        onChange={() => { }}
                        blockedDates={getDisabledDates()}
                    />
                </div>
            </div>

            <RoomAndBedSelection
                bedsContainer={bedsContainer}
                handleRoomSelection={handleRoomSelection}
                properyDetails={properyDetails}
                scrollLeftHandlerForBed={scrollLeftHandlerForBed}
                scrollRightHandlerForBed={scrollRightHandlerForBed}
                selectedRoom={selectedRoom}
                selectedBed={selectedBed}
                setSelectedBed={setSelectedBed}
            />
            {
                properyDetails && <BlockUnblockButton properyDetails={properyDetails} selectedRoom={selectedRoom}
                    confirmBlockUnblock={confirmBlockUnblock} isBlocking={isBlocking} selectedBed={selectedBed} />
            }

        </>
    )
}
