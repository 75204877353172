import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import moment from 'moment';
import { IoCaretBackOutline, IoCaretForwardOutline } from 'react-icons/io5';

const HotbedCustomCalender = ({ blockedDates = [], getDayStyle, currentMonth, setCurrentMonth, selectedDates, setSelectedDates, minDate, maxDate, onChange, }) => {
  const [hoverDate, setHoverDate] = useState(null);
  const isBeforeMinDate = (date) => minDate && date.isBefore(minDate, 'day');
  const isAfterMaxDate = (date) => maxDate && date.isAfter(maxDate, 'day');
  const weekDays=['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const isBlocked = (date) => {
    const blocked = blockedDates.find((blockedDate) =>
      dayjs(blockedDate.date).isSame(date, 'day')
    );
    return blocked && blocked.status === 'full-day';
  };

  const bookingStatus = (date) => {
    const blocked = blockedDates.find((blockedDate) => dayjs(blockedDate.date).isSame(date, 'day'));
    return blocked ? blocked.status : null;
  };

  const isRangeBlocked = (startDate, endDate) => {
    let current = startDate;
    while (current.isBefore(endDate) || current.isSame(endDate, 'day')) {
      if (isBlocked(current)) {
        return true;
      }
      current = current.add(1, 'day');
    }
    return false;
  };

  const handleSelectDate = (date) => {
    if (isBlocked(date) || isBeforeMinDate(date) || isAfterMaxDate(date)) return;

    if (selectedDates.length === 0) {
      setSelectedDates([date]);
    } else if (selectedDates.length === 1) {
      const [startDate] = selectedDates;

      if (date.isBefore(startDate, 'day')) {
        setSelectedDates([date])
        return
      }

      if (date.isSame(startDate, 'day')) {
        return
      }
      const endDate = date.isBefore(startDate, 'day') ? startDate : date;

      if (isRangeBlocked(startDate, endDate)) {
        setSelectedDates([endDate])
        toast.info('Cannot select a range that includes booked dates.');
        return;
      }

      setSelectedDates([startDate, endDate]);
    } else {
      setSelectedDates([date]);
    }
  };

  const handleHoverDate = (date) => {
    setHoverDate(date);
  };

  const isInRange = (date) => {
    if (selectedDates.length !== 2) return false;
    const [startDate, endDate] = selectedDates;
    return date.isAfter(startDate) && date.isBefore(endDate);
  };

  const selectedDateClass = (selectedDates = [], date) => {
    if (selectedDates.length === 2 && selectedDates[0].isSame(date, 'day') && selectedDates[1].isSame(date, 'day')) {
      return 'rounded-10'
    }
    if (selectedDates.length === 1 && selectedDates[0].isSame(date, 'day')) {
      return 'rounded-10'
    }
    if (selectedDates.length === 2 && selectedDates[0].isSame(date, 'day')) {
      return 'rounded-l-10'
    }
    if (selectedDates.length === 2 && selectedDates[1].isSame(date, 'day')) {
      return 'rounded-r-10'
    }

    return 'range-class'
  }


  const renderDate = (date) => {
    const baseClass = 'tw-flex tw-h-10 tw-mb-1 tw-text-sm tw-text-[#1d2429] tw-items-center tw-justify-center tw-cursor-pointer tw-relative';
    const isSelected = selectedDates.some((selectedDate) => selectedDate.isSame(date, 'day'));
    let saturdayDateClass = date.day() === 6 ? ' rounded-r-10 ' : ''
    let sundayDateClass = date.day() === 0 ? ' rounded-l-10 ' : ''
    const status = bookingStatus(date);
    const inRange = isInRange(date);
    const isHovered = hoverDate && hoverDate.isSame(date, 'day');
    const monthEndDateClass = date.isSame(date.endOf('month'), 'day') ? ' rounded-r-10 ' : '';
    const monthStartDateClass = date.isSame(date.startOf('month'), 'day') ? ' rounded-l-10 ' : '';

    const selectedClass = isSelected ? ` tw-text-white h-7 tw-bg-[rgb(35,66,110)] tw-text-white ${saturdayDateClass}  ${monthEndDateClass}  ${selectedDateClass(selectedDates, date)} ${monthStartDateClass} ${sundayDateClass} ` : '';

    const blockedClass = (status === 'full-day' || isBeforeMinDate(date) || isAfterMaxDate(date))
      ? ' tw-bg-[#F8F8F8] tw-text-[#B3BDC3] tw-cursor-not-allowed'
      : '';
    const rangeClass = inRange ? ` tw-bg-[rgb(35,66,110)] h-7 tw-text-white ${saturdayDateClass} ${sundayDateClass} ${monthEndDateClass} ${monthStartDateClass} ` : '';
    const hoverClass = isHovered ? ' ' : '';

    return (
      <div
        key={date.format('YYYY-MM-DD')}
        className={`${baseClass}`}
        onClick={() => handleSelectDate(date)}
        onMouseEnter={() => handleHoverDate(date)}
        onMouseLeave={() => setHoverDate(null)}
      >
        <div style={{ ...getDayStyle(date.format('YYYY-MM-DD')) }} className={`tw-z-0 tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full ${selectedClass} ${blockedClass} ${rangeClass} ${hoverClass} ${isBeforeMinDate(date) ? 'tw-bg-gray-100' : ''}`}>
          <span className="tw-z-20" >{date.date()}</span>
        </div>
      </div>
    );
  };

  const generateCalendar = () => {
    const startOfMonth = currentMonth.startOf('month').day();
    const daysInMonth = currentMonth.daysInMonth();
    const dates = Array.from({ length: startOfMonth }, () => null);

    for (let day = 1; day <= daysInMonth; day++) {
      dates.push(currentMonth.date(day));
    }

    return dates.concat(Array(42 - dates.length).fill(null));
  };

  const renderCalendar = () => {
    const dates = generateCalendar();
    return (
      <div className="tw-grid tw-grid-cols-7">
        {dates.map((date, index) =>
          date ? renderDate(date) : <div key={index} />
        )}
      </div>
    );
  };

  const handlePreviousMonth = () => {
    const newMonth = currentMonth.subtract(1, 'month');
    if (newMonth.isBefore(dayjs(), 'month')) {
      return;
    }
    setCurrentMonth(newMonth);
  };

  const handleNextMonth = () => {
    const newMonth = currentMonth.add(1, 'month');
    if (maxDate && newMonth.isAfter(maxDate, 'month')) {
      return;
    }
    setCurrentMonth(newMonth);
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedDates)
    }

  }, [selectedDates])

  return (
    <div className="tw-w-full">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0.5rem",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          gap: "0.5rem",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          background: "#eff2f7",
        }}
      >
        <div
          className={`tw-border tw-flex-1  tw-text-center tw-bg-white tw-text-sm  tw-p-2 tw-cursor-pointer custom_rounded-10`}
          style={{ flex: 1 }}>
          {selectedDates && selectedDates.length > 0 ? moment(selectedDates[0].toDate()).format('MMM DD, YYYY') : 'MM DD, YYYY'}
        </div>

        {selectedDates && selectedDates.length > 1 && <div className={`tw-border tw-text-center  tw-flex-1 tw-bg-white   tw-text-sm custom_rounded-10 tw-p-2 tw-cursor-pointer`}
          style={{ flex: 1 }}>
          {selectedDates && selectedDates.length === 2 ? moment(selectedDates[1].toDate()).format('MMM DD, YYYY') : 'MM DD, YYYY'}
        </div>
        }
      </div>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <div onClick={handlePreviousMonth} className="tw-bg-gray-100 tw-cursor-pointer tw-hover:tw-bg-[#e1e7f0] tw-ml-0 tw-p-1 tw-rounded">
          <IoCaretBackOutline className='tw-text-black' />
        </div>
        <h3 className="tw-text-lg">{currentMonth.format('MMMM YYYY')}</h3>
        <div onClick={handleNextMonth} className=" tw-text-black tw-bg-gray-100 tw-cursor-pointer tw-hover:tw-bg-[#e1e7f0] tw-ml-0 p-1 tw-rounded">
          <IoCaretForwardOutline />
        </div>
      </div>
      <div className="tw-gap-2 tw-grid tw-grid-cols-7 tw-mb-2 tw-border">
        {weekDays.map((day) => (
          <div key={day} className="tw-text-center">{day}</div>
        ))}
      </div>
      <div>{renderCalendar()}</div>
    </div>
  );
};

export default HotbedCustomCalender;
